import React, {Component} from 'react';
import {logout, refreshPage} from "../common/helpers";
import {userContext} from "../common/user-context";
import './form.sass';
import Config from "../config";
import RenderForm from "./render-form";
import FormLinks from "./form-links";
import ESCClient from "../api/esc";
import {NotificationManager} from 'react-notifications';

class Form extends Component{

  state = {
    formData: {},
    invalidKeys: [],
    formSubmissionInProgress: false,
  }

  constructor(props) {
    super(props)
    this.initialiseESC()
    this.logout = this.logout.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  render() {
    return (
      <div className={'form-page'}>
        <FormLinks forms={this.props.state.forms} selectForm={this.selectForm} selectedForm={this.props.state.selectedForm} breadcrumbAction={this.props.breadcrumbAction} visit={this.props.state.visit} selectedPatient={this.props.state.selectedPatient} completedForms={this.props.state.completedForms}/>
        {this.props.state.selectedForm ? <RenderForm form={this.props.state.selectedForm} setFormData={this.setFormData} removeFormData={this.removeFormData} submitForm={this.submitForm} setFormDataAsync={this.setFormDataAsync} invalidKeys={this.state.invalidKeys} breadcrumbAction={this.props.breadcrumbAction} formSubmissionInProgress={this.state.formSubmissionInProgress} visit={this.props.state.visit} visitDate={this.props.state.visitDate}/> : ''}
      </div>
    );
  }

  initialiseESC() {
    ESCClient.REST.apiURL = Config.API_URL;
  }

  selectForm = (form) => {
    this.resetFormData();
    this.props.selectForm(form)
    window.scrollTo(0, 0)
  }

  setFormData = (key, value) => {
    this.setState({formData: {...this.state.formData, ...{[key]: value}}})
  }

  setFormDataAsync = (key, value) => {
    return new Promise((resolve) => {
      this.setState({formData: {...this.state.formData, ...{[key]: value}}}, resolve)
    });
  }

  resetFormData = () => {
    this.setState({formData: {}, invalidKeys: []});
  }

  removeFormData = (key) => {
    delete this.state.formData[key]
  }

  logout() {
    logout();
    this.context.setUser({});
    refreshPage();
  }

  submitForm = () => {
    this.setState({formSubmissionInProgress: true})
    const invalidKeys = []
    const schemaName = this.props.state.selectedForm.filename
    const selectedPatient = this.props.state.selectedPatient.externalId
    const schema = this.props.state.selectedForm.json.schema
    const formData = this.state.formData;
    Object.keys(formData).forEach(function(key) {
      if(schema[key].required) {
        if(formData[key] === null) {
          invalidKeys.push(key);
        }
      }
    });
    this.setState({invalidKeys: invalidKeys});
    if(invalidKeys.length === 0) {
      console.log('form data:')
      console.log(JSON.stringify(formData))
      const data = JSON.stringify(formData);
      ESCClient.CatalogService.pushEventToPerson({
        personId: selectedPatient,
        schemaName: schemaName,
        $entity: data,
        $callback: function(code, req, data){
          this.setState({formSubmissionInProgress: false});
          console.log('code', code);
          console.log('data', data);
          const json = JSON.parse(data);
          if (code >= 200 && code <= 299 && json.valid) {
            this.props.history.goBack()
            NotificationManager.success('Form submitted', 'Success');
          }
          else {
            if(!json.valid) {
              NotificationManager.error('Form data not valid', 'Error');
            }
            else if(json.error) {
              NotificationManager.error('Form contains errors', 'Error');
            }
            else {
              NotificationManager.error('API error', 'Error');
            }

          }
          console.log('json.valid', json.valid);
          console.log('json.error', json.error);
        }.bind(this)
      })
    } else {
      this.setState({formSubmissionInProgress: false});
    }
  }

}

Form.contextType = userContext;

export default Form;
