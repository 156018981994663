import React from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "../login/login";
import Home from "../home/home";
import AddData from "../add-data/add-data";
import CreatePatient from "../create-patient/create-patient";
import ViewData from "../view-data/view-data";

export default function Routes() {
  return (
      <Switch>
        <Route
            path="/login"
            component={Login}
        />
        <RestrictedRoute
            path="/add-data"
            component={AddData}
        />
        <RestrictedRoute
          path={"/create-patient"}
          component={CreatePatient}
          />
        <RestrictedRoute
            path={"/view-data"}
            component={ViewData}
        />
        <RestrictedRoute
          path="/"
          component={Home}
        />
      </Switch>
  )


  function RestrictedRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                (localStorage.getItem("ESCJWT") === null) ? (
                    <Redirect to="/login" />
                ) : (
                    <Component {...rest} {...props} />
                )
            }
        />
    );
  }
}