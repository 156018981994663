import React, {Component} from 'react';
import FormElement from "./form-element";
import Breadcrumb from "../breadcrumb/breadcrumb";
import $ from "jquery";

class RenderForm extends Component{

  componentDidMount() {
    this.setInitialFormData()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.invalidKeys !== this.props.invalidKeys) {
      this.scrollToIssue()
    }
  }


  render() {
    const json = this.props.form.json
    return (
      <div className={'rendered-form'}>
        <Breadcrumb breadcrumb={[{path: './', name: 'Home'}, {flowNumber: 0, name: 'Patient', path: '#'}, {flowNumber: 1, name: 'Visit', path: '#'}, {flowNumber: 2, name: 'Forms', path: '#'}]} breadcrumbAction={this.props.breadcrumbAction} />
        <div className={'heading'}>
          <div className={'title'}>{json.title}</div>
          <div className={'description'}>{json.description}</div>
        </div>
        {json.form.map((formElement, index) => {
          let schema = null
          if (formElement.hasOwnProperty('key')) {
            schema = json.schema[formElement.key]
          }
          const key = formElement.key;
          const isPrefilled = (key === 'visit-number') || (key === 'visit-date');
          return isPrefilled ? null :<FormElement key={index} element={formElement} schema={schema} setFormData={this.props.setFormData}
                              json={json} removeFormData={this.props.removeFormData} submitForm={this.props.submitForm}
                              setFormDataAsync={this.props.setFormDataAsync} invalidKeys={this.props.invalidKeys}
                              formSubmissionInProgress={this.props.formSubmissionInProgress} visit={this.props.visit} visitDate={this.props.visitDate}/>
        })}
        <p><span className={'required'}>* Required field</span></p>
      </div>
    );
  }

  async setInitialFormData() {
    const json = this.props.form.json
    for (const formElement of json.form) {
      if (formElement.hasOwnProperty('key')) {
        const schema = json.schema[formElement.key]
        let value = schema.hasOwnProperty('default') ? schema['default'] : null
        if(formElement.key === 'visit-date') {
          value = this.props.visitDate.valueOf()
        }
        if(formElement.key === 'visit-number') {
          value = this.props.visit
        }
        await this.props.setFormDataAsync(formElement.key, value);
      }
    }
  }

  scrollToIssue() {
    if($(".required-banner").get(0)) {
      $(".required-banner").get(0).scrollIntoView()
    }
  }
}

export default RenderForm;