import React, {Component} from 'react';
import Navbar from "../navbar/navbar";
import PatientSelector from "./patient-selector";
import Container from "react-bootstrap/Container";
import ESCClient from "../api/esc";
import Config from "../config";
import {jwtValid, logout, refreshPage} from "../common/helpers";
import VisitSelector from "./visit-selector";
import Form from "../form/form";
import * as _ from "lodash";
import LoadingSpinner from "../common/loading-spinner";
import JsonAPI from "../form/json-api";
import {NotificationManager} from "react-notifications";
import moment from 'moment';

class AddData extends Component{

  state = {
    flowNumber: 0,
    selectedStudy: null,
    selectedPatient: null,
    patientAction: null,
    selectedForm: null,
    studies: [],
    visit: null,
    forms: [],
    schemaNames: [],
    visitDate: moment(Date.now()),
    loading: false,
    patientResult: null,
    userNotFound: false,
    patientId: '',
    usePatientParam: false,
    completedForms: [],
    invalidDate: false,
  }

  constructor(props) {
    super(props)
    this.initialiseESC()
    this.selectStudy = this.selectStudy.bind(this)
    this.selectPatient = this.selectPatient.bind(this)
    this.selectForm = this.selectForm.bind(this)
    this.closeForm = this.closeForm.bind(this)
    this.breadcrumbAction = this.breadcrumbAction.bind(this)
    this.selectVisit = this.selectVisit.bind(this)
    this.setVisitDate = this.setVisitDate.bind(this)
    this.getAllData = this.getAllData.bind(this)
  }

  componentDidMount() {
    this.initialise()
    this.getStudies()
    window.onpopstate = () => {
      this.popState();
    };
  }

  render() {
    return (
        <React.Fragment>
          <Navbar />
          <Container className={'mt-3 add-data'}>
            {this.state.loading ? <LoadingSpinner /> : ''}
            {this.state.flowNumber === 0 ? <PatientSelector selectPatient={this.selectPatient} patientSearch={this.patientSearch} patientResult={this.state.patientResult} userNotFound={this.state.userNotFound} handlePatientIdChange={this.handlePatientIdChange} patientId={this.state.patientId} studies={this.state.studies}/> : ''}
            {this.state.flowNumber === 1 ? <VisitSelector breadcrumbAction={this.breadcrumbAction} selectVisit={this.selectVisit} selectedPatient={this.state.selectedPatient} setVisitDate={this.setVisitDate} visitDate={this.state.visitDate} invalidDate={this.state.invalidDate} selectedStudy={this.state.selectedStudy}/> : ''}
            {this.state.flowNumber === 2 || this.state.flowNumber === 3 ? <Form breadcrumbAction={this.breadcrumbAction} history={this.props.history} state={this.state} selectForm={this.selectForm}/> : ''}
          </Container>
        </React.Fragment>
    );
  }

  initialiseESC() {
    ESCClient.REST.apiURL = Config.API_URL;
  }

  breadcrumbAction(flowNumber) {
    switch(flowNumber) {
      case 0:
        this.initialise();
        break;
      case 1:
        this.deselectVisit();
        break;
      case 2:
        this.closeForm();
        break;
      default:
        this.initialise();
    }

  }

  selectStudy(study) {
    this.setState({selectedStudy: study, flowNumber: 1});
    this.props.history.push('/patient-data/select-patient')
  }

  selectVisit(visit) {
    const minLimit = moment('2019-01-01', 'YYYY-MM-DD');
    const maxLimit = moment('2050-12-31', 'YYYY-MM-DD');
    const selectedDate = moment(this.state.visitDate, 'YYYY-MM-DD');
    if(selectedDate.isBefore(maxLimit) && selectedDate.isAfter(minLimit)) {
      this.setState({visit: visit, flowNumber: 2});
      this.getDataCount()
      this.props.history.push('/add-data/select-patient/visit/forms')
    } else {
      this.setState( { invalidDate: true });
    }
  }

  setVisitDate = event => {
    let inputValue = event.target.value;
    const momentDate = moment(inputValue, 'YYYY-MM-DD');
    const dateLimit = moment('2050-12-31', 'YYYY-MM-DD');
    if (momentDate.isValid() && momentDate.isBefore(dateLimit)) {
      this.setState({visitDate: momentDate, invalidDate: false});
    }
  }

  selectPatient(patient) {
    const studyId = patient.studyId
    const study = this.state.studies.find(study => study.id.toString() === studyId.toString())
    this.setState({selectedPatient: patient, selectedStudy: study, flowNumber: 1}, this.listSchemaNames);
    // this.setState({selectedPatient: patient, selectedStudy: study, flowNumber: 1}, this.getFormLinks);
    this.props.history.push('/add-data/select-patient/visit')
  }

  initialise() {
    this.setState({selectedStudy: null, selectedPatient: null, flowNumber: 0, selectedForm: null, visit: null});
    const query = new URLSearchParams(window.location.search);
    if (query.get("patient-id")) {
      const patientId = query.get("patient-id")
      this.setState({patientId: patientId, usePatientParam: true}, () => {
        this.patientSearch()
      })
    } else {
      this.props.history.push('/add-data/select-patient');
    }
  }

  deselectVisit() {
    this.setState({visit: null, flowNumber: 1, selectedForm: null});
    this.props.history.push('/add-data/select-patient/visit')
  }

  selectForm(form) {
    this.setState({selectedForm: form, flowNumber: 3});
    this.props.history.push(`/add-data/select-patient/visit/forms?filename=${form.filename}`);
  }

  closeForm() {
    this.getDataCount()
    this.setState({selectedForm: null, flowNumber: 2});
    this.props.history.push('/add-data/select-patient/visit/forms');
  }

  popState() {
    switch(this.state.flowNumber) {
      case 3:
        this.closeForm();
        break;
      case 2:
        this.deselectVisit();
        break;
      case 1:
        this.initialise();
        break;
      default:
        this.initialise();
    }
  }

  // setPatientAction(action) {
  //   this.setState({patientAction: action})
  //   switch(action) {
  //     case PATIENT_ACTION.selectForm:
  //       this.setState({flowNumber: 3});
  //       this.props.history.push('/patient-data/select-patient/action/forms');
  //       break;
  //     case PATIENT_ACTION.viewSubmissions:
  //       this.setState({flowNumber: 4});
  //       this.props.history.push('/patient-data/select-patient/action/submissions');
  //       break;
  //     case PATIENT_ACTION.uploadFiles:
  //       this.setState({flowNumber: 5});
  //       this.props.history.push('/patient-data/select-patient/action/files');
  //       break;
  //     default:
  //       this.setState({flowNumber: 2});
  //       this.props.history.push('/patient-data/select-patient/action');
  //   }
  // }

  getStudies(){
    if(jwtValid){
      // Fetch the list of visible studies
      ESCClient.StorageService.listProjects({
        $callback: function(code, req, data){
          if(code >= 200 && code <= 299) {
            this.setState({studies: data});
          }
          if(code === 500) {
            logout()
            refreshPage()
          }
        }.bind(this)
      });
    }
  }

  getFormLinks = async () => {
    const forms = await Promise.all(Config.FILENAMES.map(async filename => {
      const json = await this.readJson(`${filename}.json`);
      return {filename: filename, json: json}
    }))
    const sortedForms = _.sortBy(forms, "json.order");
    this.setState({forms: sortedForms})
  }

  readJson = async (filename) => {
    const response = await JsonAPI.getJson(filename);
    let json = {}
    try {
      json = await response.json();
    } catch(e) {
      alert(`Unable to read json file ${filename}`);
      console.error('error with file', filename);
      console.error(e);
    }
    return json
  }

  listSchemaNames(){
    this.setState({loading: true});
    const selectedStudy = this.state.selectedStudy
    const instance = this;
    if(selectedStudy != null){
      ESCClient.CatalogService.listSchemaNames({
        id: selectedStudy.externalId,
        $callback: function(code, req, data){
          if(code >= 200 && code <= 299) {
            this.setState({schemaNames: data}, () => {
              instance.getSchemas().then(() => {
                this.setState({loading: false});
              })
            })
          }
        }.bind(this)
      })
    }
  }

  async getSchemas() {
    const selectedStudy = this.state.selectedStudy
    const forms = []
    await Promise.all(this.state.schemaNames.map( async(name) => {
      await new Promise((resolve, reject) => {
        ESCClient.CatalogService.getSchema({
          id: selectedStudy.externalId,
          name: name,
          $callback: function (code, req, data) {
            if (code >= 200 && code <= 299) {
              try {
                resolve(forms.push({filename: name, json: JSON.parse(data)}));
              } catch(e) {
                alert(`Unable to read json file ${name}`);
                console.error('error with file', name);
                console.error(e);
              }
            }
            else {
              reject()
            }
          }
        });
      });
    }));
    const sortedForms = _.sortBy(forms, "json.order");
    this.setState({forms: sortedForms});
  }

  patientSearch = (e) => {
    if(e) {
      e.preventDefault()
    }
    this.setState({patientResult: null, userNotFound: false});
    const patientId = this.state.patientId
    ESCClient.CatalogService.getPersonByExternalId({
      id: patientId,
      $callback: function(code, req, data){
        if(code >= 200 && code <= 299) {
          if(this.state.usePatientParam) {
            this.setState({patientResult: data}, () => {
              this.selectPatient(data);
            });
          }
          else {
            this.setState({patientResult: data});
          }
        }
        else {
          this.setState({userNotFound: true});
        }
      }.bind(this)
    })
  }

  handlePatientIdChange = event => {
    this.setState({ patientId: event.target.value });
  }

  getDataCount() {
    const studyId = this.state.selectedStudy.externalId
    const patientId = this.state.selectedPatient.externalId
    ESCClient.CatalogService.getEventCountForObject({
      id: studyId,
      gatewayId: patientId,
      $callback: function(code, req, data){
        if(req.status >= 200 && req.status < 300) {
          this.getAllData(studyId, data, patientId);
        }
        else {
          NotificationManager.error('Unable to retrieve data', 'Error');
        }
      }.bind(this)
    })
  }

  getAllData(studyId, count, patientId) {
    ESCClient.CatalogService.queryEventsForObject({
      id: studyId,
      gatewayId: patientId,
      startIndex: 0,
      count: count,
      $callback: function(code, req, data){
        if(req.status >= 200 && req.status < 300) {
          const rawJson = JSON.parse(data)
          const parsedJson = rawJson.map((r) => JSON.parse(r));
          const completedForms = [...new Set(parsedJson.map(item => {
            return { name: item.eventType, visit: item.data['visit-number'] || null };
          }))];
          this.setState({completedForms: completedForms})
        }
        else {
          NotificationManager.error('Unable to retrieve data', 'Error');
        }
      }.bind(this)
    });
  }
}

export default AddData;
