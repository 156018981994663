import React, {Component} from 'react';
import Sticky from 'react-stickynode';

class HelpInput extends Component{

  render() {
    const helpContent = <div className={'help'} dangerouslySetInnerHTML={{ __html: this.props.element.helpvalue }}/>;
    return (
        this.props.element.sticky ? <Sticky enabled={true} bottomBoundary={this.props.element.stickyId ? `.boundary-${this.props.element.stickyId}` : 0}>{helpContent}</Sticky> : this.props.element.stickyRelease ? <div className={`boundary-${this.props.element.stickyId}`}/> : helpContent
    );
  }
}

export default HelpInput;