import React, {Component} from 'react';
import Breadcrumb from "../breadcrumb/breadcrumb";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import ChangeDataPanel from "../add-data/change-data-panel";

class FormLinks extends Component{

  render() {
    return (
      <div className={this.props.selectedForm ? 'hidden' : ''}>
        <Breadcrumb breadcrumb={[{path: './', name: 'Home'}, {flowNumber: 0, name: 'Patient', path: '#'}, {flowNumber: 1, name: 'Visit', path: '#'}]} breadcrumbAction={this.props.breadcrumbAction} />
        <ChangeDataPanel property={'Patient'} value={this.props.selectedPatient.externalId} changeAction={() => this.props.breadcrumbAction(0)}/>
        <ChangeDataPanel property={'Visit'} value={this.props.visit} changeAction={() => this.props.breadcrumbAction(1)}/>
        <Row>
          <Col>
            <h4>Forms</h4>
            <ListGroup className={'form-list'}>
            {this.props.forms.map((form, index) => {
              const visit = this.props.visit
              const completed = this.props.completedForms.find(f => f.name === form.filename && f.visit === visit);
              if(!form.json.visit || (form.json.visit && form.json.visit.includes(visit))) {
                return <ListGroup.Item key={index}>
                  <Row>
                    <Col sm={8}>
                      {completed ? <div className={'completed-container'}><span className={'completed'}>Completed</span>
                      </div> : ''}
                      <div className={'title'}>
                        {form.json.title}
                      </div>
                      <div className={'description'}>
                        {form.json.description}
                      </div>
                    </Col>
                    <Col sm={4} className={'my-auto'}>
                      <Button className={'blue-button float-right'}
                              onClick={() => this.props.selectForm(form)}>Select</Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              }
              return null
            })}
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }

}

export default FormLinks;
