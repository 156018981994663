import React, {Component} from 'react';
import * as _ from "lodash";

class CheckboxInput extends Component{

  state = {
    selected: []
  }

  render() {
    const element = this.props.element
    const schema = this.props.schema
    const alignClass = element.align === "horizontal" ? 'flex-row flex-wrap' : 'flex-column'

    return (
        <div className={`d-flex ${alignClass}`}>
          {
            schema.items.enum.map((e, index) => {
              return <div className={'checkbox-element'} key={index}>
                <input className={'form-control'} type={'checkbox'} name={element.key} onChange={this.setValue} required={this.props.schema.required}
                       value={e}/>
                <label className={'mt-2 mr-3'}>{e}</label>
              </div>
            })
          }
        </div>
    );
  }

  setValue = event => {
    const selected = this.state.selected.some(v => (v === event.target.value)) ? _.without(this.state.selected, event.target.value) : [...this.state.selected, event.target.value];
    this.setState({selected: selected})
    this.props.setFormData(this.props.element.key, selected.length ? selected : null)
  }
}

export default CheckboxInput;
