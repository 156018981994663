import React, {Component} from "react";
import ESCClient from "../api/esc";
import Config from "../config";
import {jwtValid, logout, refreshPage} from "../common/helpers";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { FaCheck } from 'react-icons/fa';


class StudySelector extends Component{

  state = {
    studies: []
  }

  constructor(props) {
    super(props)
    this.initialiseESC()
  }

  componentDidMount() {
    this.getStudies();
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h4>Select a study</h4>
            <ListGroup className={'study-list'}>
              {this.state.studies.map((study, index) => {
                const selected = study === this.props.selectedStudy
                return <ListGroup.Item key={index} onClick={() => this.props.selectStudy(study)}>
                  <Row>
                    <Col sm={8}>
                      <div className={'title'}>
                        {study.name}
                      </div>
                      <div className={'description'}>
                        {study.description}
                      </div>
                    </Col>
                    <Col sm={4} className={'my-auto'}>
                      <Button className={`${selected ? 'selected-item' : 'unselected-item'} float-right`}
                              onClick={() => this.props.selectStudy(study)}><FaCheck/></Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              })}
            </ListGroup>
          </Col>
        </Row>
        <button className={'blue-button mt-3 w-100'} disabled={this.props.selectedStudy === null} onClick={this.props.createPatient}>Create patient</button>
      </React.Fragment>
    );
  }

  initialiseESC() {
    ESCClient.REST.apiURL = Config.API_URL;
  }

  getStudies(){
    if(jwtValid){
      this.props.setLoading(true)
      // Fetch the list of visible studies
      ESCClient.StorageService.listProjects({
        $callback: function(code, req, data){
          if(code >= 200 && code <= 299) {
            this.setState({studies: data});
          }
          if(code === 500) {
            logout()
            refreshPage()
          }
          this.props.setLoading(false);
        }.bind(this)
      });
    }
  }
}

export default StudySelector;