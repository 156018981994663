import React, {Component} from 'react';

class TextInput extends Component{
  constructor(props) {
    super(props);
    this.schema = this.props.schema
    this.regex = this.schema.regex
  }

  state = {
    isValid: true,
    warning: ''
  }

  render() {
    return (
        <React.Fragment>
          <input className={'form-control'} type={'text'} onChange={this.setValue} required={this.props.schema.required} onBlur={this.onBlur}/>
          <div className={'warning'}>{this.state.warning}</div>
        </React.Fragment>
    );
  }

  setValue = event => {
    const value = event.target.value;
    this.validateRegex(value)
    this.props.setFormData(this.props.element.key, value)
  }

  validateRegex = value => {
    const isValid = new RegExp(this.regex).test(value);
    this.setState({isValid: isValid})
  }

  onBlur = () => {
    if (!this.state.isValid) {
      this.props.setFormData(this.props.element.key, null)
      this.setState({warning: `Value must match the regex pattern: ${this.regex}`})
    } else {
      this.setState({warning: ''})
    }
  }
}

export default TextInput;