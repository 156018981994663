import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import './change-data-panel.sass';

class ChangeDataPanel extends Component {

  render() {
    return (
        <Col sm={6} md={4} lg={3} className={'ml-auto mb-2 change-data-panel'}>
          <Row>
            <Col sm={4} className={'title'}>
              {this.props.property}
            </Col>
            <Col sm={8} className={'val-col'}>
              <Row>
                <Col sm={6} className={'value'}>
                  {this.props.value}
                </Col>
                <Col sm={6} className={'change-button orange-button'} onClick={this.props.changeAction}>
                  Change
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
    )
  }

}

export default ChangeDataPanel