import React, {Component} from 'react';

class TextArea extends Component{

  render() {
    return (
        <textarea className={'form-control'} onChange={this.setValue} required={this.props.schema.required}/>
    );
  }

  setValue = event => {
    this.props.setFormData(this.props.element.key, event.target.value)
  }
}

export default TextArea;