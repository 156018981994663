import React, {Component} from "react";
import '../form/form.sass'
import Breadcrumb from "../breadcrumb/breadcrumb";
import moment from 'moment';
import {INPUT_TYPE} from "../common/enums";

class ViewDataItem extends Component{
  render() {
    const json = this.props.form.json
    const schema = json.schema
    const data = this.props.data
    return (
        <div className={'view-data-item'}>
          <Breadcrumb breadcrumb={[{path: './', name: 'Home'}, {name: 'View data', path: '#', flowNumber: 0}]} breadcrumbAction={this.props.breadcrumbAction} />
          <div className={'rendered-form'}>
            <div className={'float-right text-right'}>
              <div className={'visit-number'}>Visit number: {data['visit-number'] || "Not available"}</div>
              <div className={'visit-date'}>Visit date: {moment(data['visit-date']).format("YYYY-MM-DD") || "Not available"}</div>
            </div>
            <div className={'heading'}>
              <div className={'title'}>{json.title}</div>
              <div className={'description'}>{json.description}</div>
            </div>
            {Object.keys(data).map((key, index) => {
              const type = this.getObjectType(json.form, key);
              if(schema[key] && (key !== 'visit-number') && (key !== 'visit-date')) {
                const value = type === INPUT_TYPE.date ? moment(data[key]).format("YYYY-MM-DD") : data[key]
                return <div className={'question'} key={index}>
                  <div className={'title'}>
                    {schema[key].title}
                  </div>
                  <div className={'description'}>
                    {schema[key].description}
                  </div>
                  <div className={'value'}>
                    <input type={'text'} disabled={true} value={value} className={'form-control'} />
                  </div>
                </div>
              }
              return null
            })}
            <button onClick={this.props.removeSelectedData} className={'grey-button w-100 mt-3'}>
              Back
            </button>
          </div>
        </div>
    )
  }

  getObjectType = (theObject, keyToFind) => {
    var result = null;
    if(theObject instanceof Array) {
      for(var i = 0; i < theObject.length; i++) {
        result = this.getObjectType(theObject[i], keyToFind);
        if (result) {
          break;
        }
      }
    }
    else
    {
      for(var prop in theObject) {
        if(prop === "key") {
          if(theObject[prop] === keyToFind) {
            return theObject.type;
          }
        }
        if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          result = this.getObjectType(theObject[prop], keyToFind);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  }
}

export default ViewDataItem
