import React, {Component} from 'react';
import IfHandler from "./if-handler";

class AllOfHandler extends Component{

  render() {
    const element = this.props.element
    const allOf = element.allOf || []
    return allOf.map((formElement, index) => {
      const hasIfKey = formElement.hasOwnProperty("if")
      if (hasIfKey) {
        return <IfHandler element={formElement} value={this.props.value} setFormData={this.props.setFormData}
                          json={this.props.json} removeFormData={this.props.removeFormData}
                          setFormDataAsync={this.props.setFormDataAsync} submitForm={this.props.submitForm}
                          invalidKeys={this.props.invalidKeys} key={index}/>
      }
      return ''
    })
  }
}

export default AllOfHandler;