import React, {Component} from 'react';
import {DATA_TYPE} from "../common/enums";

class NumberInput extends Component{
  constructor(props) {
    super(props);
    this.schema = this.props.schema
    this.min = this.schema.minimum
    this.max = this.schema.maximum
    this.defaultValue = this.schema.default
    this.type = this.schema.type
    this.required = this.props.schema.required
  }

  state = {
    isValid: true,
    warning: ''
  }

  render() {
    if(this.type === DATA_TYPE.integer) {
      let i;
      const options = [<option key={'empty'} value={null}></option>]
      for (i = this.min; i <= this.max; i++) {
        options.push(<option key={i} value={i}>{i}</option>)
      }
      return (
          <select className={'form-control'} onChange={this.setValue} required={this.required} defaultValue={this.defaultValue}>
            {options}
          </select>
      );
    }
    else {
      return (
          <React.Fragment>
            <input className={'form-control'} type={'number'} min={this.min} max={this.max} defaultValue={this.defaultValue} onChange={this.setValue} onBlur={this.onBlur}/>
            <div className={'warning'}>{this.state.warning}</div>
          </React.Fragment>
      );
    }
  }

  setValue = (event) => {
    let value = event.target.value
    value = parseFloat(value);
    const isValid = value >= this.min && value <= this.max
    this.props.setFormData(this.props.element.key, value)
    this.setState({isValid: isValid})
  }

  onBlur = () => {
    if(!this.state.isValid) {
      this.props.setFormData(this.props.element.key, null)
      this.setState({warning: `Value must be between ${this.min} and ${this.max}`})
    }
    else {
      this.setState({warning: ''})
    }
  }
}

export default NumberInput;
