import React, {Component} from "react";
import ESCClient from "../api/esc";
import Config from "../config";
import Navbar from "../navbar/navbar";
import Container from "react-bootstrap/Container";
import {NotificationManager} from "react-notifications";
import PatientSelector from "../add-data/patient-selector";
import {jwtValid, logout, refreshPage} from "../common/helpers";
import DataSelector from "./data-selector";
import ViewDataItem from "./view-data-item";
import moment from 'moment';

class ViewData extends Component{

  state = {
    selectedStudy: null,
    selectedPatient: null,
    patientAction: null,
    selectedForm: null,
    studies: [],
    visit: null,
    forms: [],
    schemaNames: [],
    visitDate: moment(Date.now()),
    loading: false,
    patientResult: null,
    userNotFound: false,
    patientId: '',
    usePatientParam: false,
    patientData: null,
    viewData: null
  }

  constructor(props) {
    super(props)
    this.initialiseESC()
    this.initialise()
    this.selectPatient = this.selectPatient.bind(this)
    this.viewData = this.viewData.bind(this)
    this.removeSelectedData = this.removeSelectedData.bind(this)
  }

  componentDidMount() {
    this.getStudies()
    window.onpopstate = () => {
      this.popState();
    };
  }

  render() {
    return (
        <React.Fragment>
          <Navbar />
          <Container className={'mt-3'}>
            {/*<Breadcrumb breadcrumb={[{path: '/', name: 'Home'}]} />*/}
            {/*<div>View Data</div>*/}
            {this.state.viewData === null ? <PatientSelector selectPatient={this.selectPatient} patientSearch={this.patientSearch} patientResult={this.state.patientResult} userNotFound={this.state.userNotFound} handlePatientIdChange={this.handlePatientIdChange} patientId={this.state.patientId} studies={this.state.studies}/> : ''}
            {this.state.selectedPatient && this.state.viewData === null ? <DataSelector patientData={this.state.patientData} viewData={this.viewData}/> : ''}
            {this.state.viewData ? <ViewDataItem data={this.state.viewData.data} form={this.state.selectedForm} removeSelectedData={this.removeSelectedData} breadcrumbAction={this.removeSelectedData} /> : ''}
          </Container>
        </React.Fragment>
    )
  }

  initialiseESC() {
    ESCClient.REST.apiURL = Config.API_URL;
  }

  getStudies(){
    if(jwtValid){
      // Fetch the list of visible studies
      ESCClient.StorageService.listProjects({
        $callback: function(code, req, data){
          if(code >= 200 && code <= 299) {
            this.setState({studies: data});
          }
          if(code === 500) {
            logout()
            refreshPage()
          }
        }.bind(this)
      });
    }
  }

  getDataCount() {
    const studyId = this.state.selectedStudy.externalId
    const patientId = this.state.selectedPatient.externalId
    ESCClient.CatalogService.getEventCountForObject({
      id: studyId,
      gatewayId: patientId,
      $callback: function(code, req, data){
          if(req.status >= 200 && req.status < 300) {
            this.getAllData(studyId, data);
          }
          else {
            NotificationManager.error('Unable to retrieve data', 'Error');
          }
        }.bind(this)
      })
  }

  getAllData(studyId, count) {
    const patientId = this.state.selectedPatient.externalId
    ESCClient.CatalogService.queryEventsForObject({
      id: studyId,
      gatewayId: patientId,
      startIndex: 0,
      count: count,
      $callback: function(code, req, data){
        if(req.status >= 200 && req.status < 300) {
          const rawJson = JSON.parse(data)
          const parsedJson = rawJson.map((r) => JSON.parse(r));
          this.setState({patientData: parsedJson})
        }
        else {
          NotificationManager.error('Unable to retrieve data', 'Error');
        }
      }.bind(this)
    });
  }

  selectPatient(patient) {
    const studyId = patient.studyId
    const study = this.state.studies.find(study => study.id.toString() === studyId.toString())
    this.setState({selectedPatient: patient, selectedStudy: study}, () => {
      this.getDataCount()
    });
  }

  patientSearch = (e) => {
    if(e) {
      e.preventDefault()
    }
    this.setState({patientResult: null, userNotFound: false, selectedPatient: null});
    const patientId = this.state.patientId
    ESCClient.CatalogService.getPersonByExternalId({
      id: patientId,
      $callback: function(code, req, data){
        if(code >= 200 && code <= 299) {
          if(this.state.usePatientParam) {
            this.setState({patientResult: data}, () => {
              this.selectPatient(data);
            });
          }
          else {
            this.setState({patientResult: data});
          }
        }
        else {
          this.setState({userNotFound: true});
        }
      }.bind(this)
    })
  }

  handlePatientIdChange = event => {
    this.setState({ patientId: event.target.value });
  }

  viewData(viewData) {
    ESCClient.CatalogService.getSchema({
      id: viewData.metadata._studyCode,
      name: viewData.eventType,
      $callback: function (code, req, data) {
        if (code >= 200 && code <= 299) {
          const selectedForm = {filename: viewData.eventType, json: JSON.parse(data)}
          this.setState({viewData: viewData, selectedForm: selectedForm})
          this.props.history.push('/view-data/view')
        }
      }.bind(this)
    })

  }

  initialise() {
    this.props.history.push('/view-data');
  }

  removeSelectedData() {
    this.setState({viewData: null}, this.initialise);
  }

  popState() {
    this.removeSelectedData()
  }
}

export default ViewData
