import React, {Component} from "react";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import './visit-selector.sass';
import ChangeDataPanel from "./change-data-panel";
import Alert from "react-bootstrap/Alert";

class VisitSelector extends Component{
  render() {
    const { additionalProperties } = this.props.selectedStudy;
    const studyVisits = additionalProperties?.StudyVisits?.split(',') ?? [];
    const t0ListItem = (studyVisits.length === 0 || studyVisits.includes('T0')) && (
      <ListGroup.Item>
        <span className="visit">T0 (Pre-screening)</span>
        <Button className="blue-button float-right" onClick={() => this.props.selectVisit('t0')}>Select</Button>
      </ListGroup.Item>
    );
    const t1ListItem = (studyVisits.length === 0 || studyVisits.includes('T1')) && (
      <ListGroup.Item>
        <span className={'visit'}>T1 (Month 0)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t1')}>Select</Button>
      </ListGroup.Item>
    );
    const t2ListItem = (studyVisits.length === 0 || studyVisits.includes('T2')) && (
      <ListGroup.Item>
        <span className={'visit'}>T2 (Month 6 ± 4 Weeks / HC = Month 12 ± 4 Weeks)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t2')}>Select</Button>
      </ListGroup.Item>
    );
    const t3ListItem = (studyVisits.length === 0 || studyVisits.includes('T3')) && (
      <ListGroup.Item>
        <span className={'visit'}>T3 (Month 12 ± 4 Weeks)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t3')}>Select</Button>
      </ListGroup.Item>
    );
    const t4ListItem = (studyVisits.length === 0 || studyVisits.includes('T4')) && (
      <ListGroup.Item>
        <span className={'visit'}>T4 (Month 18 ± 4 Weeks)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t4')}>Select</Button>
      </ListGroup.Item>
    );
    const t5ListItem = (studyVisits.length === 0 || studyVisits.includes('T5')) && (
      <ListGroup.Item>
        <span className={'visit'}>T5 (Month 24 ± 8 Weeks)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t5')}>Select</Button>
      </ListGroup.Item>
    );
    const t6ListItem = (studyVisits.length === 0 || studyVisits.includes('T6')) && (
      <ListGroup.Item>
        <span className={'visit'}>T6 (Month 36 ± 4 Weeks)</span>
        <Button className={'blue-button float-right'} onClick={() => this.props.selectVisit('t6')}>Select</Button>
      </ListGroup.Item>
    );

    return (
      <div className={'visit-selector'}>
        <Breadcrumb breadcrumb={[{path: './', name: 'Home'}, {flowNumber: 0, name: 'Patient', path: '#'}]} breadcrumbAction={this.props.breadcrumbAction} />
        <ChangeDataPanel property={'Patient'} value={this.props.selectedPatient.externalId} changeAction={() => this.props.breadcrumbAction(0)}/>
        <Row className={'mb-3'}>
          <Col>
            <h4>Assessment date</h4>
            <Alert variant={'danger'} hidden={!this.props.invalidDate}>
              Please enter a valid assessment date
            </Alert>
            <input type={'date'} className={'form-control'} onChange={this.props.setVisitDate} value={this.props.visitDate.format('YYYY-MM-DD')} max="2050-12-31" min="2019-01-01" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Visit</h4>
            <ListGroup className={'visit-list'}>
              {t0ListItem}
              {t1ListItem}
              {t2ListItem}
              {t3ListItem}
              {t4ListItem}
              {t5ListItem}
              {t6ListItem}
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }

}

export default VisitSelector;
