import React, {Component} from 'react';
import {userContext} from "../common/user-context";
import './login.sass'
import Navbar from "../navbar/navbar";
import ESCClient from "../api/esc";
import Config from "../config";

class Login extends Component{
  state = {
    username: '',
    password: '',
    jwt: ''
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className={'login-page'}>
          <div className={'login-panel'}>
            <h2>Please sign in</h2>
            <form className={'login-form'} onSubmit={this.handleSubmit}>
              <div className={'input-group'}>
                <label htmlFor="email">Email address</label>
                <input className={'email'} type={'email'} onChange={this.handleUsernameChange} value={this.state.username} />
              </div>
              <div className={'input-group'}>
                <label htmlFor="password">Password</label>
                <input className={'password'} type={'password'} onChange={this.handlePasswordChange} value={this.state.password} />
              </div>
              <button className={'login-button green-button'}>Sign in</button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    this.login();
  }

  login() {
    ESCClient.REST.apiURL = Config.API_URL;
    const instance = this;

    ESCClient.TokenService.issueToken({
      username: this.state.username,
      password: this.state.password,
      label: "MobiliseD Web",
      $callback: function(code, req, data){
        if(code >= 200 && code <= 299) {
          instance.setState({jwt: data.token}, () => {
            if (instance.state.jwt) {
              window.localStorage.setItem("ESCJWT", instance.state.jwt);
              // window.localStorage.setItem("ESCDATA", JSON.stringify(data));
              instance.props.history.push('/');
            }
          });
        }
        else {
          alert('invalid user details')
        }
      }});
  }

  // login = event => {
  //   event.preventDefault();
  //   const email = this.state.email;
  //   const password = this.state.password;
  //   LoginAPI.login(email, password).then( async response => {
  //     if(response.status === 200) {
  //       const json = await response.json();
  //       localStorage.setItem('access-token', json.data.attributes.accessToken);
  //       localStorage.setItem('update-token', json.data.attributes.updateToken);
  //       setUserProfile().then(async response => {
  //         if(response.status === 200) {
  //           const json = await response.json();
  //           const userData = json.data.attributes;
  //           this.context.setUser(userData)
  //           this.props.history.push('/');
  //         }
  //       });
  //     } else if(response.status === 401) {
  //       alert('Invalid login details');
  //     }
  //     else {
  //       alert('API error, please try again later');
  //     }
  //   });
  // }

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  }
}

Login.contextType = userContext;

export default Login;