import React, {Component} from 'react';
import './create-patient.sass';
import Navbar from "../navbar/navbar";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Container from "react-bootstrap/Container";
import StudySelector from "./study-selector";
import ESCClient from "../api/esc";
import Config from "../config";
import {NotificationManager} from "react-notifications";
import LoadingSpinner from "../common/loading-spinner";
import PatientCreated from "./patient-created";

class CreatePatient extends Component{

  state = {
    selectedStudy: null,
    selectedPatient: null,
    loading: false
  }

  constructor(props) {
    super(props)
    this.initialiseESC()
    this.selectStudy = this.selectStudy.bind(this)
    this.createPatient = this.createPatient.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.createAnotherPatient = this.createAnotherPatient.bind(this)
    this.addData = this.addData.bind(this)
  }

  render() {
    return (
        <React.Fragment>
          <Navbar />
          <Container className={'mt-3'}>
            <Breadcrumb breadcrumb={[{path: './', name: 'Home'}]} />
            {this.state.loading ? <LoadingSpinner /> : ''}
            {this.state.selectedPatient === null ? <StudySelector selectStudy={this.selectStudy} selectedStudy={this.state.selectedStudy} createPatient={this.createPatient} setLoading={this.setLoading}/> : ''}
            {this.state.selectedPatient ? <PatientCreated selectedPatient={this.state.selectedPatient} createAnotherPatient={this.createAnotherPatient} addData={this.addData}/> : ''}
          </Container>
        </React.Fragment>
    );
  }

  initialiseESC() {
    ESCClient.REST.apiURL = Config.API_URL;
  }

  selectStudy(study) {
    this.setState({selectedStudy: this.state.selectedStudy === study ? null : study});
  }

  createPatient() {
    this.setLoading(true);
    const selectedStudy = this.state.selectedStudy
    ESCClient.CatalogService.createPersonInStudy({
      studyCode: selectedStudy.externalId,
      $callback: function(code, req, data){
        if(req.status >= 200 && req.status < 300) {
          this.setState({selectedPatient: data})
        }
        else {
          NotificationManager.error('Unable to create patient, please try again', 'Error');
        }
        this.setLoading(false);
      }.bind(this)
    })
  }

  setLoading(bool) {
    this.setState({loading: bool})
  }

  createAnotherPatient() {
    this.setState({selectedPatient: null, selectedStudy: null})
  }

  addData() {
    this.props.history.push(`/add-data?patient-id=${this.state.selectedPatient.externalId}`)
  }
}

export default CreatePatient;