export function logout() {
  removeUser();
  removeTokens();
}

export function removeUser() {
  localStorage.removeItem('authenticated-user');
}

export function removeTokens() {
  localStorage.removeItem('ESCJWT');
  // release token endpoint not invalidating token
  // ESCClient.REST.apiURL = Config.API_URL;
  // const escData = localStorage.getItem("ESCDATA");
  // ESCClient.TokenService.releaseToken({
  //   id: escData.id,
  //   $callback: function(code, req, data){
  //     console.log('code', code)
  //     console.log('req', req)
  //     console.log('data', data)
  //   }.bind(this)
  // });
}

export function jwtValid() {
  return localStorage.getItem("ESCJWT") !== null
}

export function refreshPage() {
  window.location.reload();
}