import React, {Component} from 'react';
import FormElement from "./form-element";

class IfHandler extends Component{

  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value) {
      this.setInitialFormData()
    }
  }

  render() {
    const element = this.props.element
    const conditionMet = (this.props.value != null) && (this.props.value.toString() === element.if.const.toString())
    const thenRender = element.hasOwnProperty('then') ? this.formElements(element.then) : '';
    const elseRender = element.hasOwnProperty('else') ? this.formElements(element.else) : '';
    return (
      <div className={'if-handler'}>
        {conditionMet ?  thenRender : elseRender}
      </div>
    );
  }

  formElements = (elements) => {
    const json = this.props.json
    return elements.map((formElement, index) => {
        let schema = null
        if(formElement.hasOwnProperty('key')) {
          schema = json.schema[formElement.key]
        }
        return <FormElement key={index} element={formElement} schema={schema} setFormData={this.props.setFormData} json={json} removeFormData={this.props.removeFormData} setFormDataAsync={this.props.setFormDataAsync} invalidKeys={this.props.invalidKeys}/>
    });
  }

  async setInitialFormData() {
    const element = this.props.element
    const conditionMet = this.props.value && element.if.const && this.props.value.toString() === element.if.const.toString()
    const thenElements = element.hasOwnProperty('then') ? element.then : [];
    const elseElements = element.hasOwnProperty('else') ? element.else : [];
    for (const formElement of conditionMet ? thenElements : elseElements) {
      if (formElement.hasOwnProperty('key')) {
        const schema = this.props.json.schema[formElement.key]
        const value = schema.hasOwnProperty('default') ? schema['default'] : null
        await this.props.setFormDataAsync(formElement.key, value);
      }
    }
  }
}

export default IfHandler;
