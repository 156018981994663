import React, {Component} from 'react';
import Spinner from "react-bootstrap/Spinner";
import './loading-spinner.sass'

class LoadingSpinner extends Component {
  render() {
    return (
        <div className={'spinner-overlay'}>
          <div className={'content'}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <h4 className={'mt-4'}>Loading ...</h4>
          </div>
        </div>
    )
  }
}

export default LoadingSpinner;