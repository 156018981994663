import React, {Component} from 'react';
import './home.sass';
import Navbar from "../navbar/navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Home extends Component{

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Container className={'mt-5 home-content'}>
          <Row>
            <Col>
              <a href={'./create-patient'} className={'home-link'}>Create patient</a>
              <div className={'link-description'}>
                If this is the patients first visit, create a patient in the system
              </div>
            </Col>
            <Col>
              <a href={'./add-data'} className={'home-link'}>Add data</a>
              <div className={'link-description'}>
                Submit forms for existing patients
              </div>
            </Col>
          </Row>
          <Row className={'mt-5'}>
            <Col>
              <a href={'./view-data'} className={'home-link'}>View data</a>
              <div className={'link-description'}>
                View existing data submissions
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }

}

export default Home;