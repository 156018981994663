import React, {Component} from "react";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert"
import './patient-selector.sass';

class PatientSelector extends Component{

  render() {
    const studyId = this.props.patientResult ? this.props.patientResult.studyId : null
    const study = studyId !== null ? this.props.studies.find(study => study.id.toString() === studyId.toString()) : null
    return (
      <div className={'patient-selector'}>
        <Breadcrumb breadcrumb={[{path: './', name: 'Home'}]} />
        <Row>
          <Col>
            <form className={'patient-search'} onSubmit={this.props.patientSearch}>
              <div className={'input-group'}>
                <h4 htmlFor="patient-id">Patient search</h4>
                <InputGroup className="mb-3">
                  <FormControl
                      placeholder="Patient ID"
                      aria-label="Patient ID"
                      onChange={this.props.handlePatientIdChange} value={this.props.patientId}
                  />
                  <InputGroup.Append>
                    <Button className={'green-button'} type={'submit'}>Search</Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </form>
          </Col>
        </Row>
        {this.props.patientResult ? <Row>
          <Col>
            <h4>Search results</h4>
            <ListGroup className={'patient-list'}>
              <ListGroup.Item>
                <span className={'patient-id'}>{this.props.patientResult.externalId}</span>
                <span className={'study-name'}>{study ? `${study.name}` : ''}</span>
                <Button className={'blue-button float-right'} onClick={() => this.props.selectPatient(this.props.patientResult)}>Select</Button>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row> : this.props.userNotFound ? <Alert variant={'danger'}>
          Patient not found
        </Alert> : ''}
      </div>
    );
  }


}

export default PatientSelector;
