class Config {
  //static API_URL = 'https://mobilised.di-projects.net/api';
  static API_URL = 'https://mobilise-cvs.ncldata.dev/api';
  static FILENAMES = [
    'carehome',
    'a-consent',
    'b-descriptives',
    'c-comorbidity',
    'd-moca',
    'e-screening-copd',
    'e-screening-ha',
    'e-screening-ms',
    'e-screening-pd',
    'e-screening-pff',
    'f-living-arrangements',
    'g-falls',
    'h-falls-description',
    'i-medication-usage',
    'j-walking-aids',
    'k-llfdi',
    'l-rabinovich',
    'm-crs',
    'e-screening',
    '3-t1-descriptives',
    '4-t1-llfdi'
  ]
}
export default Config;
