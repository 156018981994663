import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DataTable, {createTheme} from "react-data-table-component";
import moment from 'moment';

class DataSelector extends Component{
  render() {
    createTheme('theme', {
      text: {
        primary: '#4A4A4A',
        secondary: '#0c5084',
      },
      background: {
        default: '#FFFFFF',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#9B9B9B',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },

    });
    const columns = [
      {
        name: 'Upload timestamp',
        selector: 'timestamp',
        sortable: true,
        format: row => moment(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        name: 'Visit number',
        selector: 'data.visit-number',
        sortable: true,
      },
      {
        name: 'Data type',
        selector: 'eventType',
        sortable: true,
      },
      {
        cell: (row) => <button className={'blue-button'} onClick={() => this.props.viewData(row)}>View data</button>,
        button: true,
        width: '200px'
      }
    ];
    const data = this.props.patientData || []
    const customStyles = {
      headCells: {
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
        }
      }
    }
    return (
        <div className={'data-selector mt-3'}>
          <Row>
            <Col>
              <h4>Data list</h4>
              <DataTable
                columns={columns}
                data={data}
                theme="theme"
                pagination={true}
                noHeader={true}
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </div>
    )
  }
}

export default DataSelector
