import React, {Component} from 'react';
import './navbar.sass'
import {logout} from "../common/helpers";
import {userContext} from "../common/user-context";
import {default as BootstrapNavbar} from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";


class Navbar extends Component{

  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this);
  }

  render() {
    return (
        <BootstrapNavbar expand="xl">
          <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
          <BootstrapNavbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto pl-2">
              <Nav.Link href="./">Home</Nav.Link>
              <Nav.Link href="./create-patient">Create patient</Nav.Link>
              <Nav.Link href="./add-data">Add data</Nav.Link>
              <Nav.Link href="./view-data">View data</Nav.Link>
              <Nav.Link href="./" onClick={this.logout}>Logout</Nav.Link>
            </Nav>
          </BootstrapNavbar.Collapse>
          <BootstrapNavbar.Brand href="/">
            <img className={'logo'} src={'./mobilisedlogo.png'} alt={"Mobilise D Logo"}/>
          </BootstrapNavbar.Brand>
        </BootstrapNavbar>
    );
  }

  logout(e) {
    e.preventDefault();
    logout();
    this.context.setUser({});
  }
}

Navbar.contextType = userContext;

export default Navbar;