import React, {Component} from 'react';
import {DATA_TYPE} from "../common/enums";


class RadioInput extends Component{

  state = {
    selected: ''
  }

  componentDidUpdate(prevProps) {
    if(prevProps.element !== this.props.element) {
      this.setState({selected: ''});
    }
  }

  render() {
    const element = this.props.element
    const schema = this.props.schema
    const required = this.props.schema.required
    const alignClass = element.align === "horizontal" ? 'flex-row flex-wrap' : 'flex-column'
    return (
        <div className={`d-flex ${alignClass}`}>
          {schema.enum.map((e, index) => {
          let value;
          let label;
          switch(schema.type) {
            case  DATA_TYPE.string:
              label=element.titleMap ? element.titleMap[e] : e;
              value=label;
              break;
            case DATA_TYPE.number:
              label=element.titleMap ? element.titleMap[e] : e;
              value=e;
              break
            case DATA_TYPE.integer:
              label=element.titleMap ? element.titleMap[e] : e;
              value=e;
              break
            default:
              label=element.titleMap ? element.titleMap[e] : e;
              value=e;
              break
          }
          return <div className={'radio-element'} key={index}>
            <input className={'form-control'} type={'radio'} name={element.key} onChange={(e) => {this.setValue(e, schema.type)}} required={required}
                   value={value} checked={this.state.selected.toString() === value.toString()}/>
            <label className={'mt-2 mr-3'}>{label}</label>
          </div>
        })}
    </div>
    );
  }

  setValue = (event, dataType) => {
    let value = event.target.value
    if(dataType === DATA_TYPE.integer || dataType === DATA_TYPE.number) {
      value = parseInt(value)
    }
    this.setState({selected: value})
    this.props.setFormData(this.props.element.key, value)
    this.props.setValue(value)
  }
}

export default RadioInput;