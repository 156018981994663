import React, {Component} from 'react';
import {INPUT_TYPE} from '../common/enums'
import TextInput from "./text-input";
import TextArea from "./text-area";
import RadioInput from "./radio-input";
import NumberInput from "./number-input";
import HelpInput from "./help-input";
import CheckboxInput from "./checkbox-input";
import IfHandler from "./if-handler";
import AllOfHandler from "./all-of-handler";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment/moment";

class FormElement extends Component{

  state = {
    value: null,
    invalidDateMessage: '',
    dateValue: ''
  }

  componentWillUnmount() {
    if(this.props.element.hasOwnProperty('key')) {
      this.props.removeFormData(this.props.element.key);
    }
  }

  render() {
    const element = this.props.element
    const schema = this.props.schema
    let input;
    switch (element.type) {
      case  INPUT_TYPE.checkboxes:
        input = <CheckboxInput setFormData={this.props.setFormData} element={element} schema={schema} setValue={this.setValue}/>;
        break;
      case INPUT_TYPE.help :
        input = <HelpInput element={element}/>;
        break;
      case INPUT_TYPE.number :
        input = <NumberInput setFormData={this.props.setFormData} element={element} schema={schema} setValue={this.setValue}/>;
        break;
      case INPUT_TYPE.radios :
      case INPUT_TYPE.radiobuttons :
        input = <RadioInput setFormData={this.props.setFormData} element={element} schema={schema} setValue={this.setValue}/>;
        break;
      case INPUT_TYPE.submit:
        input = <button onClick={this.props.submitForm} className={'green-button form-submit'} disabled={this.props.formSubmissionInProgress}>
          Submit
          {this.props.formSubmissionInProgress ? <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner> : ''}
        </button>;
        break;
      case INPUT_TYPE.text :
        input = <TextInput setFormData={this.props.setFormData} element={element} schema={schema} setValue={this.setValue} visit={this.props.visit} visitDate={this.props.visitDate}/>;
        break;
      case INPUT_TYPE.textarea :
        input = <TextArea setFormData={this.props.setFormData} element={element} schema={schema} setValue={this.setValue}/>;
        break;
      case INPUT_TYPE.date :
        input = <input value={this.state.dateValue} type={'date'} className={'form-control'} onChange={(event) => this.addDateFieldToFormData(event, element)} max="2999-12-31" min="1900-01-01" onBlur={(e) => this.dateBlur(e, element, schema.required)}/>
        break;
      default:
        input = '';
    }
    const hasIfKey = element.hasOwnProperty("if")
    const hasAllOfKey = element.hasOwnProperty("allOf")
    return (
      <div className={'form-element'}>
        {this.props.invalidKeys.includes(this.props.element.key) ? <div className={'required-banner'}>This field is required</div> : ''}
        {this.state.invalidDateMessage ? <div className={'required-banner'}>{this.state.invalidDateMessage}</div> : ''}
        {schema && schema.title ? <label dangerouslySetInnerHTML={{ __html: `${schema.title} ${schema.required ? ' <span class="required">*</span>' : ''}` }} /> : ''}
        {schema && schema.description ? <div className={'description'} dangerouslySetInnerHTML={{ __html: schema.description }} /> : ''}
        {input}
        {hasIfKey ? <IfHandler element={element} value={this.state.value} setFormData={this.props.setFormData} json={this.props.json} removeFormData={this.props.removeFormData} setFormDataAsync={this.props.setFormDataAsync} submitForm={this.props.submitForm} invalidKeys={this.props.invalidKeys}/> : ''}
        {hasAllOfKey ? <AllOfHandler element={element} value={this.state.value} setFormData={this.props.setFormData} json={this.props.json} removeFormData={this.props.removeFormData} setFormDataAsync={this.props.setFormDataAsync} submitForm={this.props.submitForm} invalidKeys={this.props.invalidKeys}/> : ''}
      </div>
    );
  }

  setValue = (value) => {
    this.setState({value: value});
  }

  addDateFieldToFormData = (event, element) => {
    const date = new Date(event.target.value);
    const timestamp = date.getTime();
    this.props.setFormData(element.key, timestamp);
    this.setState({ dateValue: event.target.value });
  }

  dateBlur = (event, element, required) => {
    const minDate = new Date('1900-01-01');
    const maxDate = new Date('2999-12-31');
    const momentDate = moment(event.target.value, 'YYYY-MM-DD')
    const timestamp = momentDate.valueOf();

    if (timestamp >= minDate.getTime() && timestamp <= maxDate.getTime()) {
      this.setState({ dateValue: event.target.value, invalidDateMessage: '' });
      this.props.setFormData(element.key, timestamp);
    } else if (event.target.value !== '') {
      this.setState({ dateValue: '', invalidDateMessage: 'Date is not valid' });
      this.props.setFormData(element.key, null);
    }
  }

}

export default FormElement;
