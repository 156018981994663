import React, {Component} from 'react';
import './common/reset.sass';
import './app.sass';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import {userContext} from './common/user-context';
import Routes from "./routes/routes";
import {NotificationContainer} from 'react-notifications';


class App extends Component{
  state = {
    user: {}
  }
  constructor(props) {
    super(props)
    const user = JSON.parse(localStorage.getItem('authenticated-user'));
    this.state = {
      user: user || {},
      setUser: (user) => {
        localStorage.setItem('authenticated-user', JSON.stringify(user));
        this.setState({ user: user });
      },
    };
  };

  render() {
    return (
      <userContext.Provider value={this.state}>
        <Router basename={'/cvsforms'}>
          <Routes/>
          <NotificationContainer/>
        </Router>
      </userContext.Provider>
    );
  }
}

export default App;
