export const INPUT_TYPE = {
  checkboxes: 'checkboxes',
  help: 'help',
  number: 'number',
  radiobuttons: 'radiobuttons',
  radios: 'radios',
  submit: 'submit',
  text: 'text',
  textarea: 'textarea',
  date: 'date'
}

export const DATA_TYPE = {
  array: 'array',
  integer: 'integer',
  number: 'number',
  string: 'string',
}

export const PATIENT_ACTION = {
  selectForm: 'select_form',
  viewSubmissions: 'view_submissions',
  uploadFiles: 'uploadFiles'
}
