import React, {Component} from 'react';
import './breadcrumb.sass';

class Breadcrumb extends Component{

  render() {
    return (
        <div className={'breadcrumb'}>
          {this.props.breadcrumb.map((item, index) =>
              <span key={index}> <a href={item.path} onClick={(e) => this.breadcrumbAction(e, item)}>{item.name}</a> <span className={'chevron'}> { index < this.props.breadcrumb.length-1 ? '>' : ''}</span> </span>
          )}
        </div>
    );
  }

  breadcrumbAction(e, item) {
    if(this.props.breadcrumbAction && item.flowNumber !== undefined) {
      e.preventDefault()
      this.props.breadcrumbAction(item.flowNumber)
    }
  }

}

export default Breadcrumb;