import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
class PatientCreated extends Component{

  render() {
    return (
      <React.Fragment>
        <Alert variant={'success'}>
          Patient Created
        </Alert>
        <h4>Patient ID: <span className={'font-weight-bold'}>{this.props.selectedPatient.externalId}</span></h4>
        <button className={'green-button mt-3 w-100'} onClick={this.props.addData}>Add data</button>
        <button className={'blue-button mt-3 w-100'} onClick={this.props.createAnotherPatient}>Create another patient</button>
      </React.Fragment>
    );
  }

}

export default PatientCreated;